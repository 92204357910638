'use strict';

const debug = require('debug')('active-ocr-client:video'),
    mediaDeviceAdapter = require('./mediaDeviceAdapter'),
    browserDetector = require('./browserDetector'),
    backCamerasChecks =  require('./backCamerasKeywords'),
    {ScanovateCameraError} = require('./customErrors');

class Video {
    constructor(element, constraintsObj) {
        this.constraintsObj = constraintsObj;
        this._stream = this.canvas = this.context = this.lastDeviceId = null;
        this.element = element;
    }

    static drawRotatedImageOnCanvas(ctx, drawingFunc, ang = 90) {
        if (ang === 90 || ang === 270) {
            if (ang === 90) {
                ctx.setTransform(0, 1, -1, 0, ctx.canvas.width, 0)
            } else {
                ctx.setTransform(0, -1, 1, 0, 0, ctx.canvas.height)
            }
        } else {
            if (ang === 0) {
                ctx.setTransform(1, 0, 0, 1, 0, 0)
            } else {
                ctx.setTransform(-1, 0, 0, -1, ctx.canvas.width, ctx.canvas.height)
            }
        }
        drawingFunc && drawingFunc();
        ctx.setTransform(1, 0, 0, 1, 0, 0);   // reset default transform
    }

    capture(quality) {
        return new Promise((res, rej) => {
            if (!this.canvas) {
                this.canvas = document.createElement('canvas');
            }
            if (!this.context) {
                this.context = this.canvas.getContext('2d');
            }
            const max = Math.max(this.element.videoWidth, this.element.videoHeight),
                min = Math.min(this.element.videoWidth, this.element.videoHeight);

            if (this.canvas.width !== max) {
                this.canvas.width = max;
                // debug(`media recorder updateSize, width: ${canvas.width}, height: ${canvas.height}`);
            }
            if (this.canvas.height !== min) {
                this.canvas.height = min;
                // debug(`media recorder updateSize, width: ${canvas.width}, height: ${canvas.height}`);
            }
            // this.canvas.width = this.element.videoWidth;
            // this.canvas.height = this.element.videoHeight;
            const isPortrait = this.isHeightGreaterThanWidth();
            if (isPortrait) {
                Video.drawRotatedImageOnCanvas(this.context, () => {
                    this.context.drawImage(this.element, 0, 0)
                }, 270);
                // this.context.drawImage(this.element, 0, 0, this.canvas.width, this.canvas.height);
            } else {
                this.context.drawImage(this.element, 0, 0, this.canvas.width, this.canvas.height);
            }

            const base64Thing = this.canvas.toDataURL('image/jpeg', quality);
            return res(base64Thing);
            // this.canvas.toBlob((blob) => {
            //     console.log(blob);
            //     res(blob);
            // }, 'image/jpeg', quality);
        });
    }

    isHeightGreaterThanWidth() {
        if (!this.element) {
            return null;
        }
        return (this.element.videoHeight > this.element.videoWidth);
    }

    _initVideo(exactId) {
        return new Promise((res, rej) => {
            debug('video init start');
            this.element.controls = false;

            // {facingMode: 'environment', width: 720, height: 1280}

            let constrains = {
                video: {
                    facingMode: 'environment',
                    width: this.constraintsObj.width,
                    height: this.constraintsObj.height
                }, audio: false
            };
            if (exactId) {
                constrains.video.deviceId = {exact: exactId};
            }

            this.destroy(false);
            if (exactId !== null && exactId !== undefined && this.lastDeviceId !== exactId) {
                // Video._destroyOriginalVideoStream();
                this.lastDeviceId = exactId;
            }
            debug(`constrains: ${JSON.stringify(constrains)}`);
            if (this._stream === null) {
                mediaDeviceAdapter(constrains).then((stream) => {
                    debug('navigator.mediaDevices.getUserMedia called');
                    this._stream = stream;
                    res(this._stream);
                }, (e) => {
                    debug(`getUserMedia error, name: ${e && e.name}, message: ${e && e.message}`);
                    rej(new ScanovateCameraError(e && e.message));
                });
            } else {
                debug('Reusing previous stream');
                res(this._stream);
            }
        });
    }

    _attachStreamToElement() {
        debug(`_attachStreamToElement called`);
        const NO_VIDEO_ELEMENT_MESSAGE = "video element was destroyed or wasn't set";
        return new Promise((res, rej) => {
            if (this.element === null || this.element === undefined) {
                return rej(new Error(NO_VIDEO_ELEMENT_MESSAGE));
            }
            const _finishVideoSetup = () => {
                if (this.element === null || this.element === undefined) {
                    return rej(new Error(NO_VIDEO_ELEMENT_MESSAGE));
                }
                debug('calling _finishVideoSetup');
                this.element.width = this.element.videoWidth || 320;
                this.element.height = this.element.videoHeight || 240;
                // Camera init completed
                res(this.element);
            };

            this.canPlayEventListener = () => {
                if (this.element === null || this.element === undefined) {
                    return rej(new Error(NO_VIDEO_ELEMENT_MESSAGE));
                }
                const promise = this.element.play();

                if (promise !== undefined) {
                    promise.then(() => {
                        debug('Autoplay started');
                    }).catch(() => {
                        debug('Autoplay was prevented');
                        // Show a "Play" button so that user can start playback.
                    });
                }
            };
            this.playEventListener = () => {
                debug('canPlayEventListener called');
                _finishVideoSetup();
            };
            this.loadedMetadataEventListener = () => {
                if (this.element === null || this.element === undefined) {
                    return rej(new Error(NO_VIDEO_ELEMENT_MESSAGE));
                }
                debug('loadedMetadataEventListener called');
            };
            this.element.addEventListener('play', this.playEventListener, false);
            const videoReadyState = this.element.readyState;
            debug(`Video element readyState: ${videoReadyState}`);
            if (videoReadyState === 3 || videoReadyState === 4) {
                this.loadedMetadataEventListener();
                this.canPlayEventListener();
            } else {
                this.element.addEventListener('canplay', this.canPlayEventListener, false);
                this.element.addEventListener('loadedmetadata', this.loadedMetadataEventListener, false);
            }
            try {
                // Older browsers may not have srcObject
                if ("srcObject" in this.element) {
                    this.element.srcObject = this._stream;
                    debug('srcObject in video element was set');
                } else {
                    // Avoid using this in new browsers, as it is going away.
                    const urlObj = window.URL || window.webkitURL || window.mozURL || window.msURL;
                    this.element.src = urlObj.createObjectURL(this._stream);
                    debug('src in video element was set');
                }
            } catch (err) {
                debug(`can't send a stream to a video element. err name: ${err.name}, err message: ${err.message}`);
                rej(err)
            }
        });
    }


    static findLastEnumeratedDeviceId(){
        return navigator.mediaDevices.enumerateDevices().then(function (devices) {
            const filteredVideoDevices = devices.filter(function(elem){
                return (elem.kind === 'videoinput');
            });
            let finalFilteredDevices = filteredVideoDevices.filter(function(elem){
                const label = elem.label;
                const isBackLabelFound = backCamerasChecks.isLabelBackLabel(label);
                debug(`Label "${label}" is found to be backside? ${isBackLabelFound}`);
                return isBackLabelFound;
            });
            if(finalFilteredDevices.length === 0){
                finalFilteredDevices = filteredVideoDevices;
            }
            debug(`Found #${finalFilteredDevices.length} relevant devices`);
            if (finalFilteredDevices.length === 0) {
                return Promise.reject(new customErrors.CameraNoRelevantDeviceFound())
            }
            // if (finalFilteredDevices.length === 1) {
            //      return null;
            // }
            return finalFilteredDevices[finalFilteredDevices.length-1].deviceId;
        });
    }

    init() {
        const reopenCameraIfNeeded = (isSecondTime) => {
            const shouldEnumerateDevices = (browserDetector.enum.CHROME === browserDetector.detectBroswer());
            const initialPromise = shouldEnumerateDevices ? Video.findLastEnumeratedDeviceId() : Promise.resolve();
            return initialPromise.then((lastDeviceId) => {
                const shouldAttamptInitAgain = !!(isSecondTime || (lastDeviceId && lastDeviceId !== this.lastDeviceId && shouldEnumerateDevices));
                let promise = shouldAttamptInitAgain ? this._initVideo(lastDeviceId) : Promise.resolve();
                return promise.then(()=>{
                    this._attachStreamToElement();
                });
            });
        };

        return this._initVideo(this.lastDeviceId)
            .then((_) => {
                return reopenCameraIfNeeded(false);
            })
            .catch((originalError) => {
                if (originalError instanceof GetUserMediaNotImplemented) {
                    throw originalError;
                }
                return reopenCameraIfNeeded(true).catch((err) => {
                    throw err;
                });
            }).catch((err) => {
                debug(`Error enumerating devices: ${err.message}`);
                throw err;
            });
    }

    static killStream(array, optionalExtraLog) {
        optionalExtraLog && debug(`killStream optionalExtraLog: ${optionalExtraLog}`);
        let tempArrayRef = array;
        if (!array) {
            return;
        } else if (!Array.isArray(array)) {
            tempArrayRef = [array];
        }
        tempArrayRef.forEach((stream, index) => {
            debug(`Stops camera stream #${index}`);
            let tracks = (stream && stream.getTracks()) || [];
            tracks.forEach(function (track) {
                track.stop();
            });
        });
    }

    stopStreaming(forceStreamEnding) {
        debug(`stopStreaming has called`);
        if(forceStreamEnding === true || browserDetector.enum.SAFARI !== browserDetector.detectBroswer()) {
            ((this._stream && this._stream.getTracks()) || []).forEach(function (track) {
                track.stop();
            });
            this._stream = null;
        }
        if (this.element && this.element.srcObject) {
            this.element.srcObject = null;
        } else if (this.element && this.element.src) {
            this.element.src = null;
        }
    }

    removeListeners() {
        if (this.element) {
            this.element.removeEventListener('play', this.playEventListener, false);
            this.element.removeEventListener('canplay', this.canPlayEventListener, false);
            this.element.removeEventListener('loadedmetadata', this.loadedMetadataEventListener, false);
            // this.element.removeEventListener('loadedmetadata', this.canPlayEventListener, false);
            // this.element.removeEventListener('loadeddata', this.canPlayEventListener, false);
            // this.element.removeEventListener('progress', this.canPlayEventListener, false);
        }
    }

    destroy(removeElement) {
        debug(`Destroying video, removeElement: ${removeElement}`);
        this.stopStreaming(removeElement !== false);
        this.removeListeners();
        const removeElementFunc = () => {
            if (this.element) {
                debug("Removing video element");
                this.element.parentNode.removeChild(this.element);
                this.element = null;
            }
        };
        if (removeElement !== null && removeElement !== undefined) {
            if (removeElement === true) {
                removeElementFunc();
            }
        } else {
            removeElementFunc();
        }
    }
}
function ScanovateError() {
}

ScanovateError.prototype = new Error;

function GetUserMediaNotImplemented() {
    this.name = 'getUserMediaNotImplemented';
    this.message = 'getUserMedia is not implemented in this browser';
    this.stack = (new Error()).stack;
}

function CameraNoRelevantDeviceFound() {
    this.name = 'CameraNoRelevantDeviceFound';
    this.message = 'No relevant device found during enumeration';
    this.stack = (new Error()).stack;
}

GetUserMediaNotImplemented.prototype = new ScanovateError;

CameraNoRelevantDeviceFound.prototype = new ScanovateError;

module.exports = Video;