'use strict';

module.exports = {
    ScanovateCameraError
}

function ScanovateCameraError(message) {
    this.message = message;
    this.name = 'ScanovateCameraError';
}
ScanovateCameraError.prototype = new Error;