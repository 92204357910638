'use strict';

const videoFromCamera = require('./video'),
    browserDetector = require('./browserDetector'),
    // data = require('./translations/text.json'),
    {ScanovateCameraError} = require('./customErrors'),
    debug = require('debug')('active-ocr-client:manager'),
    video = require('./video'),
    translations = require('./translations/languageManager'),
    Emitter = require('component-emitter');

const dom = document.documentElement;
let instance = null;

//
// function _openFullscreen(elem) {
//     try {
//         const requestFullScreen = elem.requestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullScreen || elem.msRequestFullscreen;
//         if (requestFullScreen) {
//             const fullScreenCall = requestFullScreen.call(elem);
//             if (fullScreenCall != null && typeof fullScreenCall.then === 'function') {
//                 fullScreenCall.then(function () {
//                     debug(`Successfully enabled full-screen mode`);
//                 }).catch(err => {
//                     debug(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
//                 });
//             }
//         }
//     } catch (e) {
//     }
// }

function scanovateLoadCss(url) {
    return new Promise((resolve, reject) => {
        try {
            const link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.onload = function () {
                resolve();
            };
            link.onerror = () => {
                reject(new Error('CSS loading error'))
            };
            link.href = url;
            document.head.appendChild(link);
        } catch (err) {
            reject(err);
        }

    });
}

function applyConfig(data){
    const root = document.documentElement;
    if (data.COLORS) {
        data.COLORS.instructionsColor && root.style.setProperty('--scanovate-card-capture-instructions-color', data.COLORS.instructionsColor);
        data.COLORS.loaderColor && root.style.setProperty('--scanovate-card-capture-loader-color', data.COLORS.loaderColor);
        data.COLORS.popupBackgroundColor && root.style.setProperty('--scanovate-card-capture-popup-background-color', data.COLORS.popupBackgroundColor);
        data.COLORS.popupTextColor && root.style.setProperty('--scanovate-card-capture-popup-text-color', data.COLORS.popupTextColor);
        data.COLORS.primaryColor && root.style.setProperty('--scanovate-card-capture-primary-color', `${data.COLORS.primaryColor}`);
        data.COLORS.backArrow && root.style.setProperty('--scanovate-card-capture-back-arrow-color', `${data.COLORS.backArrow}`);
        data.COLORS.instructionsTextColor && root.style.setProperty('--scanovate-card-capture-instructions-text-color', `${data.COLORS.instructionsTextColor}`);
        data.COLORS.positionInstructionTextBackground && root.style.setProperty('--scanovate-card-capture-instructions-background-color', `${data.COLORS.positionInstructionTextBackground}`);
    }
    if (data.FONT) {
        data.FONT && root.style.setProperty('--scanovate-card-capture-font', `${data.FONT}`);
    }
    console.log(`card_capture is configured to capture #${(data && data.NUMBER_OF_IMAGES) || 1} images`);
    return data;
}

module.exports = function connect(configObj) {

    const matches = document.location.origin.match(/^https?\:\/\/([^\/:?#]+)(?:[\/:?#]|$)/i),
        serverDomain = matches && matches[1],
        serverPort = location.port,// || 443,
        serverFullAddress = `${location.protocol}//${serverDomain}:${serverPort}`;
    // scanovateLoadCss(`scanovate_card_capture/assets/main.css`);


    class Capture extends Emitter {
        constructor(configObj) {
            super();
            this.data = applyConfig.call(this, configObj || {});
            this.translation = translations.getTranslationObject((this.data && this.data.language) ?this.data && this.data.language : 'en');
            this.numOfPics = this.data.NUMBER_OF_IMAGES;
            this.initPromise;
            this.videoObj;
            this.firstPage.bind(this);
            this.img_1 = null;
            this.img_2 = null;
        }

        applyNewConfig(configObj){
            this.data = applyConfig.call(this, configObj || this.data || {});
        }

        createDiv(element, id, className, visibility, opacity, src, attributes) {
            let div = document.createElement(element);
            if (id) {
                div.setAttribute('id', id);
            }
            if (className) {
                div.classList.add(className);
            }
            if (visibility) {
                div.style.visibility = visibility;
            }
            if (opacity) {
                div.style.opacity = opacity;
            }
            if (element === 'img' && src) {
                div.src = src;
            }
            if (attributes && typeof attributes === 'object') {
                debug(`Will put the following attributes on element: ${JSON.stringify(attributes)}`);
                for (let property in attributes) {
                    debug(`Attribute '${property}' will have the following property: ${attributes[property] || ''}`)
                    div.setAttribute(property, attributes[property] || '')
                }
            }

            return div;
        }

        firstPage(img) {
            if(img) {
                setTimeout(()=>{
                    this.emit('success', {
                        "firstCapture":img,
                        "secondCapture": null
                    });
                    instance = null;
                    this.destroyFirstPage();
                    return document.createElement('div');
                },10000)
            }
            document.querySelector('html').style.background = 'black';
            const constraints = {
                // width: 1280,
                // height: 720,
                width: 1920,
                height: 1080,
                aspectRatio: 1.777777778
            };
            this.numOfPics =  this.data.NUMBER_OF_IMAGES = typeof this.data.NUMBER_OF_IMAGES === 'number' ? this.data.NUMBER_OF_IMAGES : 1;
            const images = [];
            let bodyElement;

            if (bodyElement) {
                return bodyElement
            }
            bodyElement = document.createElement('section');


            const loaderElement = this.createDiv('img', 'loader', 'scanovate-card-capture-index-loader', undefined, 1.0)
            const backButtonElement = this.createDiv('button', 'back-button', 'scanovate-card-capture-index-back-button', 'hidden', undefined);
            backButtonElement.onclick = this.destroyFirstPage.bind(this);
            const backButtonElementImg = this.createDiv('img', undefined, 'scanovate-card-capture-index-arrow', undefined, undefined)
            const mainContainerElement = this.createDiv('div', 'main-container', 'scanovate-card-capture-index-main-container', 'hidden');
            const videoContainerElement = this.createDiv('div', undefined, 'scanovate-card-capture-index-video-container');
            const videoBoxElement = this.createDiv('video', 'video', 'scanovate-card-capture-index-video', 'hidden', undefined, undefined, {
                autoplay: null,
                muted: null,
                playsinline: null
            });
            videoBoxElement.muted = true;
            const tempImgResult = this.createDiv('img', 'resultImg', 'scanovate-card-capture-index-video');
            tempImgResult.classList.add('scanovate-card-capture-index-temp-background-img');
            const instructionsTextElement = this.createDiv('h1', 'video-title', 'scanovate-card-capture-index-video-title');
            videoContainerElement.appendChild(videoBoxElement);
            videoContainerElement.appendChild(instructionsTextElement);
            const captureButtonContainer = this.createDiv('div', undefined, 'scanovate-card-capture-index-capture-button-container');
            const captureButtonElement = this.createDiv('button', 'capture-button', 'scanovate-card-capture-index-capture-button', 'hidden')
            const modalElement = this.createDiv('div', 'myModal', 'scanovate-card-capture-index-modal');
            const modalContentElement = this.createDiv('div', 'modal-content', 'scanovate-card-capture-index-modal-content')
            const escapeButton = this.createDiv('div', undefined, 'scanovate-card-capture-index-close');
            escapeButton.innerHTML += (this.data && this.data.CONFIRM_BUTTON) || this.translation.CONFIRM_BUTTON;
            // escapeButton.onclick = escapeModal();
            const modalText = this.createDiv('p', undefined, 'scanovate-card-capture-index-modal-text')
            modalText.innerHTML += (this.data && this.data.POPUP_TEXT) || this.translation.POPUP_TEXT;
            //for local running
            const modalGif = this.createDiv('img', undefined, 'scanovate-card-capture-index-modal-gif', undefined, undefined, "scanovate_card_capture/assets/flip_ocr.gif");
            bodyElement.appendChild(loaderElement);
            // bodyElement.appendChild(backButtonElement);
            bodyElement.appendChild(mainContainerElement);
            bodyElement.appendChild(modalElement);
            backButtonElement.appendChild(backButtonElementImg);
            mainContainerElement.appendChild(backButtonElement);
            mainContainerElement.appendChild(videoContainerElement);
            mainContainerElement.appendChild(captureButtonContainer);
            videoContainerElement.appendChild(instructionsTextElement);
            videoContainerElement.appendChild(tempImgResult);
            captureButtonContainer.appendChild(captureButtonElement);
            modalElement.appendChild(modalContentElement);
            modalContentElement.appendChild(modalGif);
            modalContentElement.appendChild(modalText);
            modalContentElement && (modalContentElement.innerHTML += '<hr>');
            modalContentElement.appendChild(escapeButton);

            const elementsArray = [bodyElement, backButtonElement, mainContainerElement, videoContainerElement, modalElement];

            this.videoObj = new videoFromCamera(videoBoxElement, constraints);

            const init = (dontCreateVideo) => {
                for (let i = 0; i < elementsArray.length; i++) {
                    if (elementsArray[i] === 'undefined' || elementsArray[i] === 'null') {
                        return;
                    }
                }
                // loaderElement.style.opacity = '1';
                backButtonElement.style.visibility = 'hidden';
                mainContainerElement.style.visibility = 'hidden';
                videoBoxElement.style.visibility = 'hidden';
                captureButtonElement.style.visibility = 'hidden';
                const postVideoInitFunc = function(){
                    videoContainerElement.appendChild(videoBoxElement);
                    checkOrientation();
                    loaderElement.style.opacity = '0';
                    backButtonElement.style.visibility = 'visible';
                    mainContainerElement.style.visibility = 'visible';
                    videoBoxElement.style.visibility = 'visible';
                }
                if(dontCreateVideo !== true){
                    this.initPromise = this.videoObj.init().then(res => {
                        postVideoInitFunc();
                    }).catch(e => {
                        debug(`Error name: ${e && e.name}, message: ${e && e.message}`)
                        this.emit('error', e);
                    });
                } else {
                    postVideoInitFunc();
                }
                instructionsTextElement.innerHTML = (this.data && this.data.POSITION_INSTRUCTION_FRONT_SIDE) || this.translation.POSITION_INSTRUCTION_FRONT_SIDE;
            }
            const checkOrientation = () => {
                if (window.innerWidth > window.innerHeight) {
                    // bodyElement.style.height = 'unset';
                    backButtonElement.style.marginBottom = '225px'
                    backButtonElement.style.marginRight = '15px'
                    videoBoxElement.style.maxHeight = '248px'
                    videoBoxElement.style.maxWidth = '440px'
                    instructionsTextElement.style.fontSize = '17px'
                    tempImgResult.style.maxHeight = '248px'
                    tempImgResult.style.maxWidth = '440px'
                    captureButtonContainer.style.right = '10px';
                    captureButtonContainer.style.top = '45%';
                    captureButtonContainer.style.bottom = 'unset';
                    captureButtonContainer.style.left = 'unset';
                    captureButtonContainer.style.width = '35'
                    captureButtonContainer.style.height = '35'
                    captureButtonContainer.style.marginLeft = '15'
                    mainContainerElement.style.flexDirection = "row";
                    instructionsTextElement.style.transform = 'rotate(0deg)';
                    tempImgResult.style.transform = 'rotate(0deg)';
                    // modalContentElement.style.margin = "7% auto";
                    // modalContentElement.style.height = "255";
                } else {
                    backButtonElement.style.marginBottom = '10px'
                    backButtonElement.style.marginRight = '300px'
                    bodyElement.style.height = '100%';
                    videoBoxElement.style.maxHeight = '440px'
                    videoBoxElement.style.maxWidth = '248px'
                    instructionsTextElement.style.fontSize = '14px'
                    tempImgResult.style.maxHeight = '440px'
                    tempImgResult.style.maxWidth = '440px'
                    captureButtonContainer.style.bottom = '20px';
                    captureButtonContainer.style.left = '45%';
                    captureButtonContainer.style.right = 'unset';
                    captureButtonContainer.style.top = 'unset';
                    tempImgResult.style.transform = 'rotate(90deg)'
                    captureButtonContainer.style.width = '35'
                    captureButtonContainer.style.height = '35'
                    mainContainerElement.style.flexDirection = "column";
                    instructionsTextElement.style.transform = 'rotate(90deg)';
                    // modalContentElement.style.margin = "50% auto"
                    // modalContentElement.style.height = "265px";
                }
            }
            init();
            const reusedInit = () => {
                init(true);
            }
            window.addEventListener('resize', reusedInit, false);

            const escapemodal = () => {
                modalElement.style.display = "none";
                tempImgResult.style.display = 'none';
                videoBoxElement.style.display = 'block';
            }

            const captureImage = () => {
                videoBoxElement.style.display = 'none'
                // videoContainerElement.appendChild(tempImgResult);
                tempImgResult.style.display = 'block';
                modalElement.style.display = "flex";
                instructionsTextElement.innerHTML = (this.data && this.data.POSITION_INSTRUCTION_BACK_SIDE) || this.translation.POSITION_INSTRUCTION_BACK_SIDE;
                let promiseImg = this.videoObj.capture();
                promiseImg.then(res => {
                    images.push(res);
                    tempImgResult.src = images[0];
                    if (images.length === this.numOfPics) {
                        modalElement.style.display = "none";
                        videoBoxElement.style.display = 'block';
                        tempImgResult.style.display = 'none';
                        this.img_1 = images[0];
                        this.img_2 = images[1];
                        this.emit('success', {
                            "firstCapture": images[0],
                            "secondCapture": images[1] === undefined ? null : images[1]
                        });
                        if (document.querySelector('body')) {
                            document.querySelector('body').style.background = 'unset';
                        }
                        if (this.data && this.data.SHOW_RESULTS) {
                            this.redirectToResults();

                        }
                        instance = null;
                        // debug(`KILL_STREAM_AFTER_USAGE: ${this.data.KILL_STREAM_AFTER_USAGE}`);
                        // this.destroyFirstPage(this.data && this.data.KILL_STREAM_AFTER_USAGE);
                        this.destroyFirstPage();
                    }
                })
            }

            const destroy = () => {
                window.removeEventListener('resize', checkOrientation);
            }

            // captureButtonElement.onclick = captureImage;
            if(!img){
                mainContainerElement.onclick = () => {
                    let style = window.getComputedStyle(modalElement);
                    if (style.getPropertyValue("display") === "none") {
                        captureImage();
                    }
                }
                escapeButton.onclick = escapemodal;
            }

            return bodyElement;

        }

        secondPage() {
            let texts = [];

            let img_1 = this.img_1
            let img_2 = null;
            if (this.numOfPics > 1) {
                img_2 = this.img_2;
            }
            let res = {
                "firstCapture": img_1,
                "secondCapture": img_2
            }
            this.emit('success', res);
            const bodyElement = document.createElement('main');
            const mainContainer = this.createDiv('div', 'main-container', 'scanovate-card-capture-main-container')
            const imgResultMainContainer = this.createDiv('div', undefined, 'scanovate-card-capture-image-result-container');
            const resultsContainer = this.createDiv('div', 'data-result', 'scanovate-card-capture-data-result')
            const ancherTag = this.createDiv('a');
            ancherTag.onclick = () => {
                this.redirectToCapture();

            }
            const backButton = this.createDiv('button', undefined, 'scanovate-card-capture-back-button');
            const backButtonImg = this.createDiv('img', undefined, 'scanovate-card-capture-arrow', undefined, undefined);
            const imageContiner = this.createDiv('div', 'image-result-container', 'scanovate-card-capture-image-result-container');
            const imgResultWrap_1 = this.createDiv('div', undefined, 'scanovate-card-capture-image-result-wrap');
            const imageTitle1 = this.createDiv('p', 'image-title-1', 'scanovate-card-capture-img-title');
            const imgResult1 = this.createDiv('img', 'image_1', 'scanovate-card-capture-img-result', undefined, undefined, img_1);
            const imgResultWrap_2 = this.createDiv('div', undefined, 'scanovate-card-capture-image-result-wrap');
            const imageTitle2 = this.createDiv('p', 'image-title-2', 'scanovate-card-capture-img-title');
            let imgResult2;
            if (this.numOfPics > 1) {
                imgResult2 = this.createDiv('img', 'image_2', 'scanovate-card-capture-img-result', undefined, undefined, img_2);
            }
            const title = this.createDiv('p', 'title', 'scanovate-card-capture-title')
            title.innerHTML = this.data.RESULTS_PAGE_TITLE
            const innerTitle = this.createDiv('p', 'text', 'scanovate-card-capture-inner-title')
            innerTitle.innerHTML = this.data.RESULTS_CONDITIONS_TITLE
            for (let i = 0; i < this.data.CONDITIONS.length; i++) {
                let text = this.createDiv('p', undefined, 'scanovate-card-capture-text')
                const indexLogo = this.createDiv('span', undefined, 'scanovate-card-capture-row-number');
                indexLogo.innerHTML += `${i + 1}`;
                const textData = this.createDiv('span', `inner-text-${i + 1}`);
                textData.innerHTML += this.data.CONDITIONS[i].CONDITION;
                text.appendChild(indexLogo);
                text.innerHTML += `&nbsp;&nbsp; ${this.data.CONDITIONS[i].CONDITION}`;
                texts.push(text);
            }
            const captureButtonsContinaer = this.createDiv('div', undefined, 'scanovate-card-capture-buttons-container');
            const ancherTag1 = this.createDiv('a');
            ancherTag1.onclick = () => {
                this.destroySecondPage.call(this);
                window.removeEventListener('resize', checkOrientation);
            }
            const okButton = this.createDiv('input', undefined, 'scanovate-card-capture-ok-button');
            okButton.type = 'button';
            okButton.value = this.data.CONFIRM_BUTTON;
            const ancherTag2 = this.createDiv('a');
            ancherTag2.onclick = () => {
                this.redirectToCapture();
            };
            const tryAgainButton = this.createDiv('input', undefined, 'scanovate-card-capture-tryagain-button');
            tryAgainButton.type = 'button';
            tryAgainButton.value = this.data.TRY_AGAIN_BUTTON;

            bodyElement.appendChild(mainContainer);
            mainContainer.appendChild(imgResultMainContainer);
            mainContainer.appendChild(resultsContainer);
            imgResultMainContainer.appendChild(ancherTag);
            ancherTag.appendChild(backButton);
            backButton.appendChild(backButtonImg)
            imgResultMainContainer.appendChild(imageContiner)
            imageContiner.appendChild(imgResultWrap_1)
            imgResultWrap_1.appendChild(imageTitle1);
            imgResultWrap_1.appendChild(imgResult1);
            imageContiner.appendChild(imgResultWrap_2)
            imgResultWrap_2.appendChild(imageTitle2);
            if (this.numOfPics > 1) {
                imgResultWrap_2.appendChild(imgResult2);
            }
            resultsContainer.appendChild(title)
            resultsContainer.appendChild(innerTitle)
            resultsContainer.appendChild(texts[0])
            resultsContainer.appendChild(texts[1])
            resultsContainer.appendChild(texts[2])
            resultsContainer.appendChild(captureButtonsContinaer)
            captureButtonsContinaer.appendChild(okButton)
            captureButtonsContinaer.appendChild(ancherTag1)
            captureButtonsContinaer.appendChild(ancherTag2)
            ancherTag2.appendChild(tryAgainButton)
            ancherTag1.appendChild(okButton);
            const init = () => {
                if (this.data.NUMBER_OF_IMAGES === 1) {
                    imgResultWrap_2.style.visibility = 'hidden'
                }
                if (window.innerWidth > window.innerHeight) {
                    mainContainer.style.flexDirection = "row";
                    imageContiner.style.justifyContent = "unset";
                    resultsContainer.style.alignItems = "unset";
                    resultsContainer.style.flex = 'inherit';
                    resultsContainer.style.paddingLeft = '30'
                    resultsContainer.style.paddingright = '30'
                    imgResult1.style.width = '300';
                    imgResult1.style.height = '176';
                    if (this.numOfPics > 1) {
                        imgResult2.style.width = '300';
                        imgResult2.style.height = '176';
                    }
                    title.style.fontSize = '30px';
                } else {
                    mainContainer.style.flexDirection = "column";
                    imageContiner.style.justifyContent = "space-evenly";
                    resultsContainer.style.paddingLeft = '30'
                    resultsContainer.style.paddingright = '30'
                    resultsContainer.style.alignItems = "center";
                    resultsContainer.style.flex = '0.5';
                    imgResult1.style.width = '500';
                    imgResult1.style.height = '294';
                    if (this.numOfPics > 1) {
                        imgResult2.style.width = '500';
                        imgResult2.style.height = '294';
                    }
                    title.style.fontSize = '30px';
                }
            }
            init();

            const checkOrientation = () => {
                init()
            }
            window.addEventListener('resize', checkOrientation, false);

            const destroy = () => {
                window.removeEventListener('resize', checkOrientation);
            }

            return bodyElement;

        }

        redirectToCapture(img) {
            // dom.innerHTML = '';
            // dom.childNodes[1].remove();
            // this.destroy();
            let bodyElement = this.firstPage(img);
            let body = document.querySelector('body');
            body.style.padding = '0';
            body.style.margin = '0';
            body.style.position = 'fixed';
            body.appendChild(bodyElement);
            if (document.querySelector('main')) {
                document.querySelector('main').remove()
            }
            // document.querySelector('head').innerHTML += '<link rel="stylesheet" href="scanovate_card_capture/assets/main.css" type="text/css"/>';

        }

        redirectToResults() {
            // dom.innerHTML = '';
            // this.destroyFirstPage(this.data.KILL_STREAM_AFTER_USAGE);
            this.destroyFirstPage();
            let bodyElement = this.secondPage();
            let bd = document.querySelector('body');
            bd.style.position = 'fixed';
            bd.style.margin = '0';
            bd.style.padding = '0';
            // bd.childNodes[4].remove();
            bd.appendChild(bodyElement);
            if (document.querySelector('section')) {
                document.querySelector('section').remove()
            }
            if (document.querySelector('body')) {
                document.querySelector('body').style.background = 'unset';
            }
            // document.querySelector('head').innerHTML += '<link rel="stylesheet" href="scanovate_card_capture/assets/main.css" type="text/css"/>';
        }

        init(configObj, img) {
            debug(`Card capture is initiating with the following config: ${JSON.stringify(configObj)}`);
            this.applyNewConfig(configObj || null);
            this.redirectToCapture(img);
        }

        destroyFirstPage() {
            debug(`destroy first page has called`);
            if (document.querySelector('section')) {
                document.querySelector('section').remove();
            }
            if (document.querySelector('body')) {
                document.querySelector('body').style.background = 'unset';
            }
            instance = null;
            document.querySelector('html').style.background = 'unset';
            window.onclick = null;
            this.initPromise = null;
            if (this.videoObj !== null && this.videoObj !== undefined) {
                this.videoObj.destroy(null);
                this.videoObj = null;
            }
        }

        destroySecondPage() {
            if (document.querySelector('main')) {
                document.querySelector('main').remove();
            }
            if (document.querySelector('body')) {
                document.querySelector('body').style.background = 'unset';
            }
            document.body.style.height = 'inherent';
            document.body.style.position = 'initial';
            document.body.style.padding = 'inherent';
            document.body.style.margin = 'inherent';
        }

    }

    if (instance === null) {
        instance = new Capture()
    }

    return instance;
}



