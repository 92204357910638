'use strict';

let debug = require('debug')('active-ocr-client:browserDetector');

const enumObjForExport = {
    FIREFOX: 'Mozilla Firefox',
    OPERA: 'Opera',
    TRIDENT: 'Microsoft Internet Explorer',
    EDGE: 'Microsoft Edge',
    CHROME: 'Google Chrome or Chromium',
    SAFARI: 'Apple Safari',
    UNKNOWN: 'unknown'
};

module.exports = {
    detectBroswer,
    enum: enumObjForExport
};

function detectBroswer() {
    const sUsrAg = navigator.userAgent;
    let sBrowser;

//The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = enumObjForExport.FIREFOX;
        //"Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("Opera") > -1) {
        sBrowser = enumObjForExport.OPERA;
    } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = enumObjForExport.TRIDENT;
        //"Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = enumObjForExport.EDGE;
        //"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = enumObjForExport.CHROME;
        //"Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = enumObjForExport.SAFARI;
        //"Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        sBrowser = enumObjForExport.UNKNOWN;
    }
    
    return sBrowser;
}