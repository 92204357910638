'use strict'

const backCamerasKeywords = [
    "rear",
    "back",
    "rück",
    "arrière",
    "trasera",
    "trás",
    "traseira",
    "posteriore",
    "后面",
    "後面",
    "背面",
    "后置", // alternative
    "後置", // alternative
    "背置", // alternative
    "задней",
    "الخلفية",
    "후",
    "arka",
    "achterzijde",
    "หลัง",
    "baksidan",
    "bagside",
    "sau",
    "bak",
    "tylny",
    "takakamera",
    "belakang",
    "אחורית",
    "πίσω",
    "spate",
    "hátsó",
    "zadní",
    "darrere",
    "zadná",
    "задня",
    "stražnja",
    "belakang",
    "बैक"
]

module.exports = {
    isLabelBackLabel
}

function isLabelBackLabel(label){
    if(!label){
        return false;
    }
    for(let i = 0; i < backCamerasKeywords.length; i++){
        if(label.toLowerCase().includes(backCamerasKeywords[i])){
            return true;
        }
    }
    return false;
}